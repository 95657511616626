.contact {
    min-height: calc(100vh - 80px - 177px);
    padding-bottom: 30px;
}

.button-row button {
    min-width: 12em;
}

.button-row {
    padding-left: 90px;
    padding-bottom: 40px;
}

.contact-container {
    display: flex;

    padding: 45px 90px 15px 90px;
    justify-content: space-between;
    /* align-items: center; */
    
}

.contact-left {
    display: flex;
    flex-direction: column;
    flex: 0.6;



}

.contact-right {
    display: flex;
    flex-direction: column;
    flex: 0.3;
    background-color: #cdb30c;
    box-shadow: 0px 2px 25px -12px #cdb30c;
     border-radius: 2px;
     /* height: 50vh; */
}

.cl-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

}

.contact-left textarea {
    resize: none;
    background-color: transparent;
    color: white;
    outline-width: 0;
    margin-top: 20px;
    border: none;
    font-size: 16px;
    border-bottom: 1px solid grey;
}

.contact-left textarea:focus {
    border-color: white;
}

.check-row {
    display: flex;
    align-items: center;
    width: 100%;
}

.cl-row input {
    flex: 0.45;
}

.check-row p {
    /* cursor: pointer; */
}

.check-row .MuiCheckbox-root {
    color: grey !important;
    border-color: white;
}

.check-row .Mui-checked {
    color: white !important;
}

.contact-left input {
    background-color: transparent;
    outline-width: 0;
    font-size: 16px;
    color: white;
    border: none;
    padding: 10px;
    border-bottom: 1px solid grey;
}

.contact-left input:focus {
    border-color: white;
}

.contact-finished {
    margin-top: 10vh;
    color: white;
    padding-left: 90px;
}

.contact-finished h1 {
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
}

.error-message-row {
    color: white;
    padding-left: 90px;
    color: red;
    opacity: 0.9;
    transition: 200ms ease-in-out;
}


@media all and (min-width: 1024px) and (max-width: 1280px) { }
 
@media all and (min-width: 768px) and (max-width: 1024px) {
    .contact-finished {
        padding-left: 45px !important;
    }
 }
 
@media all and (min-width: 480px) and (max-width: 768px) {
    .contact-container {
        padding: 15px !important;
        flex-direction: column;
    }   
   
    .contact-right {
        display: none;
    }
   
    .cl-row {
        flex-direction: column;
        width: 100%;
    }
   
    .cl-row input {
        width: calc(100% - 20px);
        margin-top: 15px;
    }
   
    .check-row {
        margin-top: 30px;
    }
   
    .button-row {
        padding: 0px !important;
        margin-top: 30px;
    }

    .contact-finished {
        padding-left: 30px !important;
    }
   
    .contact-finished h1 {
       font-size: 20px;
    }
   
    .contact-finished p {
        width: 80%;
    }
 }
 
@media all and (max-width: 480px) { 

 .contact-container {
     padding: 15px !important;
     flex-direction: column;
 }   

 .contact-right {
     display: none;
 }

 .cl-row {
     flex-direction: column;
     width: 100%;
 }

 .cl-row input {
     width: calc(100% - 20px);
     margin-top: 15px;
 }

 .check-row {
     margin-top: 30px;
 }

 .button-row {
     padding: 0px !important;
     margin-top: 30px;
 }

 .contact-finished {
     padding-left: 15px !important;
 }

 .contact-finished h1 {
    font-size: 20px;
 }

 .contact-finished p {
     width: 80%;
 }
}