.service-box {
    display: flex;
    flex-direction: column;
    /* flex: 0.25; */
    width: calc(25% - 80px);
    padding: 45px 30px;
    border-radius: 5px;
    background-color: #1a1a1a;
    box-shadow: 0px 2px 25px -16px rgb(0 0 0 / 26%);
    /* margin-right: 20px; */
    /* transition: 200ms ease-in-out; */
    position: relative;
}

.service-box:hover {
    background-color: #cdb30c;
}

.service-box:hover .sb-svg {
    color: #1a1a1a !important;
}

.service-box:hover h2 {
    color: #1a1a1a !important;
}

.service-box-active {
    background-color: #cdb30c;
}

.service-box .MuiSvgIcon-root {
    height: 50px;
    width: auto;
}

.sb-svg {
    height: 50px !important;
    width: auto;
    color: #cdb30c;
}

.service-box-active .sb-svg {
    color: #1a1a1a !important;
}

.hs-text {
    /* flex: 0.5; */
    width: 50%;
    display: flex;
    flex-direction: column;
    /* margin-right: calc(10% + 40px); */
    /* margin-right: 40px; */
    flex-shrink: 0;
    margin-right: -10px;
}

.hs-text h2 {
    color: white;
    font-size: 34px;
    letter-spacing: 3px;
    text-transform: uppercase;
    /* line-height: 1.4; */

}

.hs-text p {
    color: white;
    opacity: 0.7;
    margin-top: 15px;
    width: 90%;
}

.hs-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;
    /* flex-wrap: wrap; */
}

.sb-logo {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}

.sb-text {
    display: flex;
    justify-content: flex-start;
}

.sb-text h2 {
    text-transform: uppercase;
    font-size: 18px;
    width: 70%;
    line-height: 30px;
    color: white;
}

.service-box-active h2 {
    color: #1a1a1a;
}

.sb-arrow {
    position: absolute;
    bottom: -20px;
    right: 50px;
    width: 40px;
    height: 40px;
    background-color: #1a1a1a;
    border-radius: 50%;
    border: 2px solid #cdb30c;
    align-items: center;
    display: flex;
    justify-content: center;
    /* padding: 3px 10px; */
    opacity: 0;
    transition: 400ms ease-in-out;
}

.sb-arrow:hover {
    cursor: pointer;
}

.service-box:hover .sb-arrow {
    right: 30px;
    opacity: 1;
}

.sb-arrow .MuiSvgIcon-root {
    color: #cdb30c !important;
    height: 20px !important;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }
 
@media all and (min-width: 768px) and (max-width: 1024px) { 
    .service-box h2 {
        font-size: 16px !important;
        width: 90%;
    }

    .sb-logo {
        margin-bottom: 10px !important;
    }
}
 
@media all and (min-width: 480px) and (max-width: 768px) { }
 
@media all and (max-width: 480px) { 
    .sb-logo {
        margin-bottom: 10px !important;
    }

    .service-box {
        padding: 30px !important;
    }

}