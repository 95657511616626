@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

* {
  margin: 0px;
  font-family: 'Montserrat', sans-serif;
}

.ml-20 {
  margin-left: 20px;
}

.page-header {
  display: flex;
  width: calc(100% - 90px);
  /* justify-content: center; */
  align-items: center;
  /* padding: 40px 0px; */
  text-transform: uppercase;
  /* background-color: black; */
  color: white;
  font-size: 30px;
  padding-top: 45px;
  letter-spacing: 2px;
  padding-left: 90px;
  
}

.page-sub {
  padding-left: 90px;
  color: white;
}

.page-sub p {
  opacity: 0.7;
  padding-top: 15px;
  padding-bottom: 45px;
  width: 50%;
}

.mobile-msg {
  color: white;
  margin-bottom: 45px;
  opacity: 0.5;
}

.msg-center {
  display: flex;
  justify-content: center;
}


@media all and (min-width: 1024px) and (max-width: 1280px) { }
 
@media all and (min-width: 768px) and (max-width: 1024px) {
  .page-header {
    font-size: 20px;
    padding-left: 45px;
  }
  
  .page-sub {
    padding-left: 45px;
  }

  .page-sub p {
    width: 75%;
  }
 }
 
@media all and (min-width: 480px) and (max-width: 768px) { 
  .page-header {
    font-size: 20px;
    padding-left: 30px;
  }
  
  .page-sub {
    padding-left: 30px;
  }
  
  .page-sub p {
    width: 90%;
  }
}
 
@media all and (max-width: 480px) { 
.page-header {
  padding-left: 15px;
}

.page-header h1 {
  font-size: 30px;
}

.page-sub {
  padding-left: 15px;
}

.page-sub p {
  width: 75%;
}

}