.home-banner {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 70vh;
    /* background: url('https://i.imgur.com/N4oFKfn.jpeg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 45px;
    overflow: hidden;
    /* background: url('https://i.imgur.com/yeL6yTf.jpeg'); */
    /* background: url('https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png'); */
}

.hs-text {
    /* opacity: 0.7; */
    display: flex;
}




.hb-text h1 {
    color: white;
    text-transform: uppercase;
    font-size: 60px;
    margin-bottom: 20px;
    width: 90%;
}

.hb-text p {
    color: white;
    font-size: 25px;
    width: 75%;
    font-weight: 500;
}

.hb-buttons {
    margin-top: 40px;
}

.hb-button {
    color: white;
    background-color: transparent;
    border: 3px solid white;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 30px;
    transition: 300ms ease-in-out;
    box-shadow: inset 0 0 0.8em rgba(255,255,255,0.3), 0 0 0.8em rgba(255,255,255,0.3);
    /* border-radius: 4px; */
}

.hw-buttons {
    padding-left: 90px;
    margin-top: -15px;
    margin-bottom: 15px;
}

.hw-links {
    display: flex;
    align-items: center;
    width: calc(100% - 200px);
    justify-content: space-between;
    padding: 30px 90px 180px 90px;
    flex-wrap: wrap;
}

.hb-button:hover {
    /* background-color: white; */
    color: black;
    box-shadow: 
    inset 6em 0 0 0 white,
    inset -6em 0 0 0 white;
    /* box-shadow: inset 6.5em 0 0 0 var(--hover); */
    /* border-color: lightgray; */
}

.home-services {
    display: flex;
    flex-direction: column;
    padding: 30px 90px 90px 90px ;
    /* background-color: black; */
}

.hw-header {
    display: flex;
    align-items: center;
    padding-left: 90px;
    /* justify-content: center; */
    margin: 45px 0px;
}

.hw-header h3 {
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 34px;
    }

    .home-work {
        padding-bottom: 45px;
    }


    @media all and (min-width: 1280px) {

    }
    
    @media all and (min-width: 1024px) and (max-width: 1280px) {

     }
     
    @media all and (min-width: 768px) and (max-width: 1024px) {

        .hs-text h2 {
            font-size: 24px !important;
        }

        .home-services {
            padding: 30px 45px 45px 45px;
        }

        .hw-header {
            padding-left: 45px !important;
        }

        .hw-buttons {
            padding-left: 45px !important;
        }

     }
     
    @media all and (min-width: 480px) and (max-width: 768px) {


        .home-banner {
            flex-direction: column;
            height: max-content;
        }
    
        .hb-h1 {
            display: none;
        }
    
        .hb-right {
            align-items: center !important;
        }
    
        .hb-text p {
            text-align: center;
            width: 90%;
            font-size: 20px;
        }
    
        .hb-buttons {
            display: flex;
            justify-content: center;
        }
    
        .home-services {
            padding: 30px 15px;
            /* height: max-content; */
        }



        .hs-text {
            width: 100% !important;
            text-align: center;
        }

        .hs-row {
            flex-direction: column;
            align-items: center;
            margin-bottom: 0px !important;
        }

        .service-box {
            width: 75% !important;
            margin-right: 0px !important;
            margin-top: 20px;
        }

        .home-work {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .hw-header {
            padding: 0px;
            text-align: center;
        }

        .hw-links {
            flex-direction: column;
            padding: 0px !important;
            /* width: 75vw; */
        }

        .project-box {
            width: 75vw;
            margin-bottom: 45px;
            height: 40vh !important;
            flex: auto !important;
        }

        .footer {
            flex-direction: column-reverse;
            align-items: center !important;
        }

        .footer-left {
            display: none;
        }

        .footer-right {
            padding-right: 0px !important;
            align-items: center !important;
        }

        .footer-middle {
            margin-top: 20px;
        }

        .hb-text {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        .hw-buttons {
            display: flex;
            justify-content: center;
            padding: 0 !important;
            margin-bottom: 45px;
        }
     }
     
    @media all and (max-width: 480px) { 
    

    
        .home-banner {
            flex-direction: column;
            height: max-content;
        }
    
        .hb-h1 {
            display: none;
        }
    
        .hb-right {
            align-items: center !important;
        }
        .hb-text {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    
        .hb-text p {
            text-align: center;
            width: 90%;
            font-size: 20px;
        }
    
        .hb-buttons {
            display: flex;
            justify-content: center;
        }
    
        .home-services {
            padding: 30px 15px;
            /* height: max-content; */
        }



        .hs-text {
            width: 100% !important;
            text-align: center;
        }

        .hs-text h2 {
            font-size: 20px !important;
        }

        .hs-row {
            flex-direction: column;
            align-items: center;
            margin-bottom: 0px !important;
        }

        .service-box {
            width: 75% !important;
            margin-right: 0px !important;
            margin-top: 20px;
        }

        .home-work {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .hw-header {
            padding: 0px;
            text-align: center;
        }

        .hw-links {
            flex-direction: column;
            padding: 0px !important;
            /* width: 75vw; */
        }

        .project-box {
            width: 75vw;
            margin-bottom: 20px;
            height: 40vh !important;
            flex: auto !important;
        }

        .footer {
            flex-direction: column-reverse;
            align-items: center !important;
        }

        .footer-left {
            display: none;
        }

        .footer-right {
            padding-right: 0px !important;
            align-items: center !important;
        }

        .footer-middle {
            margin-top: 20px;
        }

        .hw-buttons {
            display: flex;
            justify-content: center;
            padding: 0 !important;
            margin-bottom: 45px;
        }
    
    }