.services-content {
    display: flex;
    flex-direction: column;
}

.sc-head {
    margin-left: 90px;
    margin-bottom: 30px;
}

.sc-head h2 {
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 32px;
}

.scc-left img {
    height: 40vh;
    width: 40vw;
    object-fit: cover;
    border-radius: 5px;
}

.scc-left {
    display: flex;
    padding-left: 90px;
}

.scc-right {
    margin-left: 15px;
}

.sc-container {
    display: flex;
    margin-bottom: 90px;
}

.sc-container h3 {
    font-size: 25px;
    color: white;
    text-transform: uppercase;
    width: 90%;
}

.sc-container p {
    color: white;
    margin-top: 10px;
    width: 80%;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }
 
@media all and (min-width: 768px) and (max-width: 1024px) { }
 
@media all and (min-width: 480px) and (max-width: 768px) {
    .sc-head {
        display: flex;
        justify-content: center;
        margin-left: 0px !important;
        margin: 15px 0px 45px 0;
    }

    .sc-container {
        flex-direction: column;
        align-items: center;
    }

    .scc-left {
        padding-left: 0px !important;
    }

    .scc-left img {
        width: 75vw;
    }

    .scc-right {
        margin: 0px !important;
        text-align: center;
        align-items: center;
        width: 90%;
        margin-top: 30px !important;;
    }

    .scc-right h3 {
        width: 100%;
        font-size: 20px;
        margin: 15px 0px;
    }

    .scc-right p {
        width: 100%;
    }
 }
 
@media all and (max-width: 480px) { 

    .sc-head {
        display: flex;
        justify-content: center;
        margin-left: 0px !important;
    }

    .sc-container {
        flex-direction: column;
        align-items: center;
    }

    .scc-left {
        padding-left: 0px !important;
    }

    .scc-left img {
        width: 75vw;
    }

    .scc-right {
        margin: 0px !important;
        text-align: center;
        align-items: center;
        width: 90%;
        margin-top: 30px !important;
    }

    .scc-right h3 {
        width: 100%;
    }

    .scc-right p {
        width: 100%;
    }
}