.work {
    min-height: calc(100vh - 177px - 80px);
}

.hwl-top {
    padding-bottom: 30px !important;
}

@media all and (min-width: 1024px) and (max-width: 1280px) { }
 
@media all and (min-width: 768px) and (max-width: 1024px) { }
 
@media all and (min-width: 480px) and (max-width: 768px) { }
 
@media all and (max-width: 480px) { 

    
}