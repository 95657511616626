.project-box {
    height: 50vh;
    /* flex: 0.3; */
    width: 25vw;
    background-position: center center ;
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 8px 10px 30px -16px darkgrey;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    z-index: 0;
    border: 1px solid darkgray;
    margin-bottom: 40px;
    /* opacity: 0.7; */
}

.project-box .pb-text {
    opacity: 1;
}

/* .project-box:hover::before {
    top: 0;
    right: 0;
} */

.project-box::before {
    top: 0%;
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    background: #232429;
    width: 100%;
    height: 50%;
    content: '';
    opacity: 0.9;
    transition: 0.6s ease;
}

/* .project-box:hover::after {
    bottom: 0;
    left: 0;
} */

.project-box::after {
    bottom: 0%;
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    background: #232429;
    width: 100%;
    height: 50%;
    content: '';
    opacity: 0.9;
    transition: 0.6s ease;
}

.pb-text {
    opacity: 0;
    padding: 20px;
    transition: 0.3s ease-in;
}

.pb-text {
    color: #cdb30c;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.pb-text h3 {
    margin-top: 10px;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: white;
}

.pb-arrow {
    position: absolute;
    bottom: 32px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: #cdb30c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pb-svg {
    color: white !important;
}

.pb-arrow:hover {
    cursor: pointer;
    background-color: #1a1a1a;
}

.pb-arrow:hover .pb-svg {
    color: #cdb30c !important;
}
