.scale-in-ver-center {
	-webkit-animation: scale-in-ver-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-ver-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-11 19:14:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-ver-center {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-ver-center {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      opacity: 1;
    }
  }
  

  .slide-in-elliptic-left-fwd {
	-webkit-animation: slide-in-elliptic-left-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-left-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-11 19:15:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * @animation slide-in-elliptic-left-fwd 
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-elliptic-left-fwd {
    0% {
      -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
              transform: translateX(-800px) rotateY(30deg) scale(0);
      -webkit-transform-origin: -100% 50%;
              transform-origin: -100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotateY(0) scale(1);
              transform: translateX(0) rotateY(0) scale(1);
      -webkit-transform-origin: 1800px 50%;
              transform-origin: 1800px 50%;
      opacity: 1;
    }
  }
  @keyframes slide-in-elliptic-left-fwd {
    0% {
      -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
              transform: translateX(-800px) rotateY(30deg) scale(0);
      -webkit-transform-origin: -100% 50%;
              transform-origin: -100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotateY(0) scale(1);
              transform: translateX(0) rotateY(0) scale(1);
      -webkit-transform-origin: 1800px 50%;
              transform-origin: 1800px 50%;
      opacity: 1;
    }
  }
  

  .slide-in-elliptic-right-fwd {
	-webkit-animation: slide-in-elliptic-right-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-right-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-11 19:17:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-elliptic-right-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-elliptic-right-fwd {
    0% {
      -webkit-transform: translateX(800px) rotateY(-30deg) scale(0);
              transform: translateX(800px) rotateY(-30deg) scale(0);
      -webkit-transform-origin: -100% 50%;
              transform-origin: -100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotateY(0) scale(1);
              transform: translateX(0) rotateY(0) scale(1);
      -webkit-transform-origin: -1800px 50%;
              transform-origin: -1800px 50%;
      opacity: 1;
    }
  }
  @keyframes slide-in-elliptic-right-fwd {
    0% {
      -webkit-transform: translateX(800px) rotateY(-30deg) scale(0);
              transform: translateX(800px) rotateY(-30deg) scale(0);
      -webkit-transform-origin: -100% 50%;
              transform-origin: -100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotateY(0) scale(1);
              transform: translateX(0) rotateY(0) scale(1);
      -webkit-transform-origin: -1800px 50%;
              transform-origin: -1800px 50%;
      opacity: 1;
    }
  }
  

  .jello-horizontal {
	-webkit-animation: jello-horizontal 0.9s both;
	        animation: jello-horizontal 0.9s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-11 19:18:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  }
  

  .bg-pan-bl {
	-webkit-animation: bg-pan-bl 8s alternate both;
	        animation: bg-pan-bl 8s alternate both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-11 19:40:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bg-pan-bl
 * ----------------------------------------
 */
 @-webkit-keyframes bg-pan-bl {
        0% {
          background-position: 100% 0%;
        }
        100% {
          background-position: 0% 100%;
        }
      }
      @keyframes bg-pan-bl {
        0% {
          background-position: 100% 0%;
        }
        100% {
          background-position: 0% 100%;
        }
      }
      

      .tracking-in-expand-fwd {
	-webkit-animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}


 @-webkit-keyframes tracking-in-expand-fwd {
        0% {
          letter-spacing: -0.5em;
          -webkit-transform: translateZ(-700px);
                  transform: translateZ(-700px);
          opacity: 0;
        }
        40% {
          opacity: 0.6;
        }
        100% {
          -webkit-transform: translateZ(0);
                  transform: translateZ(0);
          opacity: 1;
        }
      }
      @keyframes tracking-in-expand-fwd {
        0% {
          letter-spacing: -0.5em;
          -webkit-transform: translateZ(-700px);
                  transform: translateZ(-700px);
          opacity: 0;
        }
        40% {
          opacity: 0.6;
        }
        100% {
          -webkit-transform: translateZ(0);
                  transform: translateZ(0);
          opacity: 1;
        }
      }
      

      .scale-in-hor-left {
	-webkit-animation: scale-in-hor-left 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-hor-left 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes scale-in-hor-left {
        0% {
          -webkit-transform: scaleX(0);
                  transform: scaleX(0);
          -webkit-transform-origin: 0% 0%;
                  transform-origin: 0% 0%;
          opacity: 1;
        }
        100% {
          -webkit-transform: scaleX(1);
                  transform: scaleX(1);
          -webkit-transform-origin: 0% 0%;
                  transform-origin: 0% 0%;
          opacity: 1;
        }
      }
      @keyframes scale-in-hor-left {
        0% {
          -webkit-transform: scaleX(0);
                  transform: scaleX(0);
          -webkit-transform-origin: 0% 0%;
                  transform-origin: 0% 0%;
          opacity: 1;
        }
        100% {
          -webkit-transform: scaleX(1);
                  transform: scaleX(1);
          -webkit-transform-origin: 0% 0%;
                  transform-origin: 0% 0%;
          opacity: 1;
        }
      }
      

      .flip-in-ver-right {
	-webkit-animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes flip-in-ver-right {
        0% {
          -webkit-transform: rotateY(-80deg);
                  transform: rotateY(-80deg);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateY(0);
                  transform: rotateY(0);
          opacity: 1;
        }
      }
      @keyframes flip-in-ver-right {
        0% {
          -webkit-transform: rotateY(-80deg);
                  transform: rotateY(-80deg);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateY(0);
                  transform: rotateY(0);
          opacity: 1;
        }
      }

.fade-in {
animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
      }


      .kenburns-top {
	-webkit-animation: kenburns-top 5s ease-out both;
	        animation: kenburns-top 5s ease-out both;
}


 @-webkit-keyframes kenburns-top {
        0% {
          -webkit-transform: scale(1) translateY(0);
                  transform: scale(1) translateY(0);
          -webkit-transform-origin: 50% 16%;
                  transform-origin: 50% 16%;
        }
        100% {
          -webkit-transform: scale(1.25) translateY(-15px);
                  transform: scale(1.25) translateY(-15px);
          -webkit-transform-origin: top;
                  transform-origin: top;
        }
      }
      @keyframes kenburns-top {
        0% {
          -webkit-transform: scale(1) translateY(0);
                  transform: scale(1) translateY(0);
          -webkit-transform-origin: 50% 16%;
                  transform-origin: 50% 16%;
        }
        100% {
          -webkit-transform: scale(1.25) translateY(-15px);
                  transform: scale(1.25) translateY(-15px);
          -webkit-transform-origin: top;
                  transform-origin: top;
        }
      }
      

      .text-focus-in {
        -webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
                animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
      }

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}


.scale-in-center {
	-webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
---------------------------------------
 */
 @-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}


.slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-14 8:51:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
