.menu-bar {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    background-color: black;
    justify-content: space-between;
    /* padding: 0vw 5vw; */
    position: sticky;
    top: 0;
    z-index: 100;
}

.mb-left img {
    cursor: pointer;
}

.mb-left {
    padding-left: 90px;
}


.mb-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 90px;
}

.nav-link {
    color: white;
    text-decoration: none;
    transition: 100ms ease-in-out;
    margin-right: 50px;
}

.nav-link-active {
    font-weight: bold;
}

.nav-link:hover {
    font-weight: bold;
}

.mb-burger {
    padding-right: 15px;
}

.drawer-menu  {
    background-color: black !important;
    display: flex;
    flex-direction: column;
    padding: 30px 15px;
}

.drawer-menu .nav-link {
    margin-bottom: 15px;
}

@media all and (min-width: 1280px) {
    .mb-burger {
        display: none;
    }
    .mb-right {
        display: flex;
    }
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
    .mb-right {
        display: flex;
    }

    .mb-burger {
        display: none;
    }
 }
 
@media all and (min-width: 768px) and (max-width: 1024px) {
    .mb-right {
        display: flex;
    }

    .mb-burger {
        display: none;
    }
 }
 
@media all and (min-width: 480px) and (max-width: 768px) {
    .mb-right {
        display: none;
    }

    .mb-burger {
        display: flex;
    }

    .mb-left {
        padding-left: 15px;
    }
 }
 
@media all and (max-width: 480px) { 

    .mb-right {
        display: none;
    }

    .mb-burger {
        display: flex;
    }

    .mb-left {
        padding-left: 15px;
    }

   

}