.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: black; */
    background-color: #1a1a1a;
    min-height: 100px;
    width: 100%;
    padding: 30px 0px;
}

.footer-left {
    flex: 0.3
}

.footer-right {
    flex: 0.3;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 90px;
}

.fr-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    opacity: 0.7;
    transition: 200ms ease-in;

}

.footer-left {
    padding-left: 90px;
}

.fr-item p {
    color: white;
    text-align: right;
}

.fr-item:hover {
    opacity: 1;
    cursor: pointer;
}

.fr-svg {
    width: 20px;
    width: auto;
    color: white;
    margin-right: 20px;
}

.footer-right h3 {
    color: white;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5;
    font-size: 24px;
}

.footer-middle {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    opacity: 0.9;
    font-size: 14px;
}